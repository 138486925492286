<template>
  <v-timeline dense>
    <v-timeline-item
      v-for="(event, i) in events"
      :key="i"
      :color="event.color"
      :icon="event.icon"
    >
      <template
        #icon
      >
        <v-avatar
          v-if="event.avatar"
          size="32"
        >
          <img :src="event.avatar">
        </v-avatar>
        <v-icon
          v-else
          dark
          small
        >
          {{ event.icon }}
        </v-icon>
      </template>
      <v-list-item
        dense
        class="pl-0"
      >
        <v-list-item-content>
          <v-list-item-title class="font-weight-light">
            {{ event.date | moment('L LTS') }}
            <v-chip
              x-small
              outlined
              label
              :color="event.color"
              class="text-uppercase font-weight-black px-2 ml-1 my-0"
            >
              {{ event.title }}
            </v-chip>
          </v-list-item-title>
          <v-list-item-subtitle class=" text-wrap">
            {{ event.text }}
            <div
              v-if="event.subtext"
              class="text-caption text-wrap"
            >
              {{ event.subtext }}
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-timeline-item>
  </v-timeline>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    statuses: {
      'data extraction': { color: 'cyan', icon: '' },
      'do not interpret': { color: 'brown darken-3', icon: '' },
      'interpreted': { color: 'green', icon: '' },
      'needs review': { color: 'error', icon: 'fa-regular fa-flag' },
      'negative': { color: 'blue-grey', icon: '' },
      'new': { color: 'orange darken-1', icon: '' },
      'in progress': { color: 'orange darken-1', icon: '' },
      'pending approval': { color: 'purple', icon: '' },
      'high confidence': { color: 'purple darken-4', icon: '' },
      'quality assurance': { color: 'pink lighten-1', icon: '' },
      'contact lab': { color: 'blue lighten-1', icon: 'fa-regular fa-headset' },
      'tech support': { color: 'yellow darken-2 theme--light', icon: '' },
      'unclassified data': { color: 'brown lighten-1', icon: 'fa-regular fa-question' },
    },
  }),
  computed : {
    icons() {
      return  {
        auto: { icon: 'fa-regular fa-hundred-points', color: 'primary' },
        confident: { icon: 'fa-regular fa-gauge-max', color: 'primary' },
        created: { icon: 'fa-regular fa-star', color: this.statuses['new'].color },
        downloaded: { icon: 'fa-regular fa-file-arrow-down', color: 'success darken-1' },
        failure: { icon: 'fa-regular fa-triangle-exclamation', color: 'error' },
        moved: { icon: 'fa-regular fa-diagram-next', color: 'primary' },
        queued: { icon: 'fa-regular fa-webhook', color: '#E91E63' },
        rejected: { icon: 'fa-regular fa-times', color: 'error' },
        retrieved: { icon: 'fa-regular fa-cloud-arrow-down', color: 'success darken-1' },
        saved: { icon: 'fa-regular fa-check', color: 'success darken-1' },
        success: { icon: 'fa-regular fa-check', color: 'success darken-1' },
        updated: { icon: 'fa-regular fa-pencil', color: this.statuses['new'].color },
      }
    },
    events() {
      let events = this.item.activity.map((activity) => {
        let event = {
          date: activity.created_at,
          title: activity.event,
          text: activity.description,
          color: this.icons[activity.event]?.color,
          icon: this.icons[activity.event]?.icon,
        }
        if (activity.causer?.avatar) {
          event.avatar = activity.causer.avatar
          event.text = `${event.text} by ${activity.causer.first_name} ${activity.causer.last_name}`
        }
        if (activity.event == 'failure') {
          event.subtext = activity.properties.errorMessage
        }

        return event
      })

      if (this.item.interpretation?.approved_at) {
        let event = {
          date: this.item.interpretation.approved_at,
          title: 'approved',
          subtitle: this.humanizeDuration(this.item.created_at, this.item.interpretation.approved_at),
          text: `Interpretation approved by ${this.item.interpretation.approver?.first_name} ${this.item.interpretation.approver?.last_name}`,
          color: 'green',
        }
        if (this.item.interpretation?.approver?.first_name == 'Auto') {
          event.icon = 'fa-regular fa-robot'
        } else {
          event.avatar = this.item.interpretation?.approver?.avatar
        }
        events.push(event)
      }

      this.item.statuses
        .filter(s => !['new', 'interpreted'].includes(s.name))
        .filter(s => s.reason == 'auto' || (s.reason && !isNaN(s.reason)) || s.name == 'unclassified data')
        .map((status) => {
          if (status.created_at) {
            let event = {
              date: status.created_at,
              color: this.statuses[status.name]?.color,
              icon: this.statuses[status.name]?.icon,
            }

            if (status.name == 'unclassified data') {
              event.title = 'unclassified'
              event.text = 'Lab result contains unclassified data'
            } else if (status.name == 'partial data') {
              event.title = 'partial data'
              event.text = 'Lab result is partially complete'
            } else {
              event.title = 'match'
              event.text = `${status.reason == 'auto' ? 'Exact ' : `${this.$options.filters.percentage(status.reason)} ${status.name}`} match found`
              event.icon = status.reason == 'auto' ? 'fa-regular fa-hundred-points' : 'fa-regular fa-gauge-max'
            }
            events.push(event)
          }
        })


      this.item.statuses
        .filter(s => ['needs review', 'tech support', 'contact lab'].includes(s.name))
        .map((status) => {
          events.push({
            date: status.created_at,
            title: status.name,
            text: `"${status.reason}"`,
            color: this.statuses[status.name]?.color,
            icon: this.statuses[status.name]?.icon,
          })
        })

      events = events.sort((a, b) => {
        return new Date(a.date) - new Date(b.date)
      })

      if (this.item.uploader.length) {
        events[0].text = `Lab result uploaded by ${this.item.uploader[0]?.first_name} ${this.item.uploader[0]?.last_name}`
        events[0].avatar = this.item.uploader[0]?.avatar
      }

      return events
    },
  },
  methods: {
    humanizeDuration(start, end) {
      return this.$moment.duration(this.$moment(start).diff(end, 'milliseconds', true)).humanize()
    },
  },
}
</script>
